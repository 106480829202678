// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Button, CardMedia, Grid, Tooltip, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import Markdown from 'markdown-to-jsx';
// import SearchLoadingImg from '../../images/loading.gif';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Breadcrumb from './breadcrumb';
import Chat from '../../images/Message.svg';
import TranslateIcon from '@mui/icons-material/Translate';
import CloseIcon from '@mui/icons-material/Close';
import { NewsletterChat } from './newsletter-chat.component'; // Import the Breadcrumb component
import defaultImage from '../../images/related-news-footer-article-default 1.png';
import MarkdownRenderer from '../search/MarkdownRenderer';
const SEARCH_URL = process.env.REACT_APP_WEB_API;
const SearchLoadingImg = process.env.REACT_APP_LOADER_URL;
const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff4f4',
  textAlign: 'center',
  width: '100%',
  margin: 'auto',
  borderRadius: 2,
}));
const CustomCardInfo = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff4f4',
  textAlign: 'center',
  width: '100%',
  margin: 'auto',
  borderRadius: 2,
}));

const SmolLMArticles = () => {
  const { id, articleId, detailsId } = useParams();
  const [article, setArticle] = useState({});
  const [relatedArticle, setRelatedArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedLoading, setRelatedLoading] = useState(true);
  const [articleLang, setArticleLang] = useState(true);
  const [relatedArticleLang, setRelatedArticleLang] = useState(true);

  const fetchArticle = () => {
    setLoading(true);
    axiosBackendApi
      .get(`${SEARCH_URL}/articles/${detailsId}`)
      .then((response) => {
        setArticle(response.data.data);
        setArticleLang(response.data.data.lang);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchRelatedArticle = () => {
    setRelatedLoading(true);
    axiosBackendApi
      .get(`${SEARCH_URL}/newsletters-reports/${articleId}/articles/${detailsId}/related_articles`)
      .then((response) => {
        // console.log(response.data.data,'article');
        setRelatedArticle(response.data.data.articles || []);
        setRelatedArticleLang((response.data.data.articles.map(article => article.lang)));

        setRelatedLoading(false);
      })
      .catch(() => {
        setRelatedLoading(false);
      });
  };

  useEffect(() => {
    fetchArticle();
    fetchRelatedArticle();
  }, [id, detailsId, articleId]);

  const breadcrumbItems = [
    { label: 'NewsLetters', path: '/newsLetters' },
    { label: 'Reports', path: `/newsLetters/${id}` },
    { label: 'NewsLetter Detail', path: `/newsletters/${id}/NewsLetterDetail/${articleId}` },
    { label: 'Article Details', isActive: true },
  ];
  const handleCloseChat = () => setOpenChat(false);
  const [openChat, setOpenChat] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isTranslated, setIsTranslated] = useState(false);
  const [translatedContent, setTranslatedContent] = useState('');
  const [translateLoading, setTranslateLoading] = useState(false);
  const [revertingLoading, setRevertingLoading] = useState(false);
  const handleOpenChat = (card) => {
    setSelectedCard(card);
    setOpenChat(true);
  };
  const handleTranslateToggle = () => {
    setTranslateLoading(true);

    if (!isTranslated) {
      const { title, summary, article: content, field, sub_field, published_at ,manchette} = article;

      // Prepare all promises for concurrent execution
      const titlePromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: title });
      const summaryPromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: summary });
      const contentPromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: content });
      const fieldPromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: field });
      const subFieldPromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: sub_field });
      const publishedAtPromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: published_at });
      const manchettePromise = axiosBackendApi.post(`${SEARCH_URL}/translate`, { query: manchette });

      // Execute all promises concurrently using Promise.all
      Promise.all([
        titlePromise,
        summaryPromise,
        contentPromise,
        fieldPromise,
        subFieldPromise,
        publishedAtPromise,
        manchettePromise
      ])
        .then(([
                 titleResponse,
                 summaryResponse,
                 contentResponse,
                 fieldResponse,
                 subFieldResponse,
                 publishedAtResponse,
                 manchetteResponse
               ]) => {
          // Extract translated values
          const translatedTitle = titleResponse.data.translate;
          const translatedSummary = summaryResponse.data.translate;
          const translatedContent = contentResponse.data.translate;
          const translatedField = fieldResponse.data.translate;
          const translatedSubField = subFieldResponse.data.translate;
          const translatedPublishedAt = publishedAtResponse.data.translate;
          const translatedManchette =manchetteResponse.data.translate ;

          // Set the translated content in state
          setTranslatedContent({
            title: translatedTitle,
            summary: translatedSummary,
            article: translatedContent,
            field: translatedField,
            sub_field: translatedSubField,
            published_at: translatedPublishedAt,
            manchette:translatedManchette
          });


          setIsTranslated(true);
          setTranslateLoading(false);
        })
        .catch(() => {
          // alert('Translation failed');
          setTranslateLoading(false);
        });
    } else {
      // If already translated, revert back
      setIsTranslated(false);
      setTranslateLoading(false);
    }
  };

  const isArabic = /[\u0600-\u06FF]/.test(article.summary?.charAt(0));

  return (
    <Grid sx={{ marginTop: 13 }}>
      {/* Render Breadcrumb */}
      <Breadcrumb items={breadcrumbItems} />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
          <img src={SearchLoadingImg} className="loading-img" alt="Loading" />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8} sx={{
             direction: isTranslated ? 'rtl' : 'ltr',
          }}>

              <CustomCard sx={{ padding: 3 }}>
              <CardMedia
                component="img"
                image={article.image_url?.startsWith('https://') ? article.image_url : defaultImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
                alt={article.title}
                sx={{ borderRadius: 3 }}
              />
                {translateLoading ?  <img src={SearchLoadingImg} className="loading-img" /> :

             <>
               <Box
                 className="flex-wrap gap-2"
                 sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center !important', marginTop: 2 }}
               >
                 <Box className="flex-wrap gap-2" sx={{ display: 'flex', gap: 2 }}>
                   <Typography
                     variant="body2"
                     component="div"
                     color="textSecondary"
                     mb={2}
                     sx={{
                       padding: '5px 10px',
                       borderRadius: 4,
                       backgroundColor: 'var(--color-light)',
                       color: 'var(--color-primary) !important',
                       fontWeight: 'bold',
                       marginBottom: 0,
                     }}
                     dir={'auto'}
                   >
                     {!isTranslated ? article.field: translatedContent.field}
                   </Typography>
                   <Typography
                     variant="body2"
                     component="div"
                     color="textSecondary"
                     mb={2}
                     sx={{
                       padding: '5px 10px',
                       marginBottom: 0,
                       borderRadius: 4,
                       backgroundColor: 'var(--color-light)',
                       color: 'var(--color-primary) !important',
                       fontWeight: 'bold',
                     }}
                     dir={'auto'}
                   >
                     <span className={'sub-titles-color'}>   {isTranslated ? translatedContent.sub_field: article.sub_field   }</span>
                   </Typography>
                   <Typography
                     variant="body2"
                     component="div"
                     color="textSecondary"
                     mb={2}
                     sx={{
                       padding: '5px 10px',
                       marginBottom: 0,
                       borderRadius: 4,
                       backgroundColor: 'var(--color-light)',
                       color: 'rgb(8, 89, 80) !important',
                       fontWeight: 'bold',
                     }}
                     dir={'auto'}
                   >
                     <span className={'sub-titles-color'} dir={'auto'}>{isTranslated ? translatedContent.published_at  : article.published_at} </span>
                   </Typography>
                 </Box>
                 <Box>

                   <Tooltip title={isTranslated ? 'Back to English' : 'Translate'}>
                     <IconButton onClick={handleTranslateToggle}>
                       {isTranslated ? (
                         <CloseIcon sx={{ fontSize: 20, stroke: '#ccc', fill: '#979E9E' }} />
                       ) : (
                         <TranslateIcon sx={{ fontSize: 20, stroke: '#ccc', fill: '#979E9E' }} />
                       )}
                     </IconButton>
                   </Tooltip>
                   <Tooltip title={'chat with ai'}>
                     <IconButton aria-label="comment" onClick={() => handleOpenChat(article)}>
                       <svg width="24" height="24" viewBox="0 0 16 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                         <path
                           d="M8.00121 8.70347C7.948 8.70263 7.89728 8.68079 7.86011 8.64272C7.82293 8.60464 7.80231 8.55342 7.80274 8.50021C7.80274 8.38817 7.89237 8.29694 8.00121 8.29694"
                           stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M4.55625 8.69707C4.53018 8.69686 4.50442 8.69152 4.48042 8.68135C4.45642 8.67119 4.43466 8.65639 4.41638 8.63781C4.3981 8.61923 4.38365 8.59724 4.37387 8.57308C4.3641 8.54892 4.35917 8.52307 4.35938 8.497C4.35938 8.38657 4.44741 8.29694 4.55625 8.29694"
                           stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M11.4488 8.69707C11.4228 8.69686 11.397 8.69152 11.373 8.68135C11.349 8.67119 11.3272 8.65639 11.309 8.63781C11.2907 8.61923 11.2762 8.59724 11.2665 8.57308C11.2567 8.54892 11.2517 8.52307 11.252 8.497C11.252 8.38657 11.34 8.29694 11.4488 8.29694"
                           stroke="#979e9e" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M8.00586 8.70347C8.03234 8.70326 8.05853 8.69784 8.08291 8.68751C8.1073 8.67718 8.12942 8.66215 8.14799 8.64328C8.16657 8.6244 8.18125 8.60205 8.19119 8.5775C8.20113 8.55295 8.20614 8.52669 8.20593 8.50021C8.20614 8.47372 8.20113 8.44746 8.19119 8.42291C8.18125 8.39836 8.16657 8.37601 8.14799 8.35713C8.12942 8.33826 8.1073 8.32323 8.08291 8.3129C8.05853 8.30257 8.03234 8.29715 8.00586 8.29694"
                           stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M4.55664 8.69707C4.5827 8.69686 4.60847 8.69152 4.63247 8.68135C4.65647 8.67119 4.67823 8.65639 4.69651 8.63781C4.71479 8.61923 4.72923 8.59724 4.73901 8.57308C4.74879 8.54892 4.75372 8.52307 4.75351 8.497C4.75372 8.47094 4.74879 8.44509 4.73901 8.42093C4.72923 8.39677 4.71479 8.37477 4.69651 8.3562C4.67823 8.33762 4.65647 8.32282 4.63247 8.31265C4.60847 8.30249 4.5827 8.29715 4.55664 8.29694"
                           stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M11.4492 8.69707C11.4753 8.69686 11.501 8.69152 11.525 8.68135C11.549 8.67119 11.5708 8.65639 11.5891 8.63781C11.6074 8.61923 11.6218 8.59724 11.6316 8.57308C11.6414 8.54892 11.6463 8.52307 11.6461 8.497C11.6463 8.47094 11.6414 8.44509 11.6316 8.42093C11.6218 8.39677 11.6074 8.37477 11.5891 8.3562C11.5708 8.33762 11.549 8.32282 11.525 8.31265C11.501 8.30249 11.4753 8.29715 11.4492 8.29694"
                           stroke="#979e9e" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                         <path
                           d="M8.0035 1.29779C6.69373 1.29823 5.4088 1.65527 4.28655 2.33059C3.16431 3.00591 2.2471 3.97403 1.63334 5.13109C1.02159 6.28785 0.737747 7.58984 0.812505 8.89626C0.887262 10.2027 1.31777 11.4638 2.05748 12.5432L0.801055 15.7027L4.1622 14.5695C5.10682 15.162 6.17597 15.5274 7.28565 15.637C8.39533 15.7466 9.51531 15.5974 10.5576 15.2012C11.5999 14.8049 12.5361 14.1723 13.2926 13.3531C14.0492 12.534 14.6055 11.5505 14.9179 10.4801C15.2277 9.40994 15.2839 8.28233 15.0822 7.18663C14.8806 6.09093 14.4264 5.05727 13.7559 4.16757C13.0849 3.27622 12.2161 2.553 11.2178 2.05497C10.2195 1.55694 9.11912 1.29773 8.0035 1.29779Z"
                           stroke="#979e9e" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                       </svg>

                     </IconButton>
                   </Tooltip>
                   <Tooltip title={'Read More'}>
                     <IconButton color="primary" href={article.url} target="_blank">
                       <ArrowCircleRightOutlinedIcon
                         sx={{ fontSize: 28, stroke: '#979E9E', fill: '#979E9E', strokeWidth: '0px' }}
                       />
                     </IconButton>
                   </Tooltip>

                 </Box>
               </Box>


               <CardContent dir={articleLang === 'ar' || isTranslated  ? 'rtl' : 'ltr'}>
                 <Typography
                   variant="h6"
                   component="div"
                   fontWeight="bold"
                   color="textPrimary"
                   sx={{
                     display: 'flex',
                     justifyContent: 'start',
                     marginBlock: 3,
                     color: '#000 !important',
                     fontSize: '30px',
                   }}
                   gutterBottom
                   dir={articleLang === 'ar' || isTranslated  ? 'rtl' : 'ltr'}
                   className={articleLang === 'ar' || isTranslated  ?'direction-rtl' : 'direction-ltr'}
                 >
                   { isTranslated ? translatedContent.title : article.title }
                   {/*{article.title}*/}
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   color="textSecondary"
                   mb={2}
                   dir={articleLang === 'ar' || isTranslated  ? 'rtl' : 'ltr'}
                   sx={{ textAlign: 'start', fontSize: '15px' }}
                 >
                   <Typography
                     variant="body2"
                     component="div"
                     color="textSecondary"
                     mb={2}
                     sx={{
                       padding: '5px 10px',
                       borderRadius: 4,
                       backgroundColor: 'var(--color-light)',
                       color: 'var(--color-primary) !important',
                       fontWeight: 'bold',
                       width: 'fit-content',
                     }}
                     dir={articleLang === 'ar' || isTranslated  ? 'rtl' : 'ltr'}
                     className={articleLang === 'ar' ?'direction-rtl' : 'direction-ltr'}
                   >
                     <span className={'sub-titles-color'} dir={'auto'}> { isTranslated ? translatedContent.manchette : article.manchette }</span>
                   </Typography>
                   <Typography
                     variant="body2"
                     component="div"
                     color="textSecondary"
                     mb={2}
                     dir={articleLang === 'ar' || isTranslated ? 'rtl' : 'ltr'}
                     className={articleLang === 'ar' || isTranslated ?'direction-rtl' : 'direction-ltr'}
                     sx={{ fontWeight: 'bold', fontSize: '17px', color:'var(--color-primary) !important' }}
                   >
                     {!isTranslated && articleLang === 'en'  ? "Summary:" : "ملخص:"}
                   </Typography>
                   <MarkdownRenderer markdownText={ isTranslated ? translatedContent.summary : article.summary }  dir={'auto'} />
                 </Typography>
                 <hr />
                 {/*<Markdown children={isTranslated ? translatedContent.article : article.article} />*/}
                 <Typography
                   variant="body2"
                   component="div"
                   color="textSecondary"
                   mb={2}
                   dir={articleLang === 'ar' || isTranslated ? 'rtl' : 'ltr'}
                   className={articleLang === 'ar' || isTranslated ?'direction-rtl' : 'direction-ltr'}
                   sx={{ fontWeight: 'bold', fontSize: '17px', color:'var(--color-primary) !important' }}
                 >
                   {!isTranslated && articleLang === 'en'  ? "Full Article:" : "المقال:"}
                 </Typography>
                   <div dir={'auto'} className={articleLang === 'ar' || isTranslated ?'direction-rtl' : 'direction-ltr'}>
                   <MarkdownRenderer markdownText={ isTranslated ? translatedContent.article : article.article } dir={'auto'}  />
                 </div>
               </CardContent> </>
                }
            </CustomCard>

          </Grid>
          <Grid item xs={12} lg={4}>
            {relatedLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
                <img src={SearchLoadingImg} className="loading-img" alt="Loading" />
              </Box>
            ) : Array.isArray(relatedArticle) && relatedArticle.length > 0 ? (
              relatedArticle.map((relatedArticle, index) => (
                <CustomCardInfo sx={{ padding: 1, paddingBottom: 0, marginBottom: 2, }} key={index}>
                  <CardMedia
                    component="img"
                    image={relatedArticle.image_url?.startsWith('https://') ? relatedArticle.image_url : defaultImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                    alt={relatedArticle.title}
                    sx={{ borderRadius: 10, height: '200px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center !important',
                      marginTop: 2,
                    }}
                    dir={relatedArticle.lang === 'ar' ? 'rtl' : 'ltr'}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                      mb={2}
                      sx={{
                        padding: '5px 10px',
                        borderRadius: 4,
                        backgroundColor: 'var(--color-light)',
                        color: 'rgb(8, 89, 80) !important',
                        fontWeight: 'bold',
                        marginBottom: 0,
                      }}
                      dir={relatedArticle.lang === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <span className={'sub-titles-color'}> {relatedArticle.field}</span>
                    </Typography>
                    <Typography variant="body2" component="div" color="textSecondary" mb={2} sx={{
                      padding: '5px 10px',
                      borderRadius: 4,
                      backgroundColor: 'var(--color-light)',
                      color: 'rgb(8, 89, 80) !important',
                      fontWeight: 'bold',
                      marginBottom: 0,
                    }}>
                      <span className={'sub-titles-color'}  dir={relatedArticle.lang === 'ar' ? 'rtl' : 'ltr'}> {relatedArticle.sub_field}</span>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                      mb={2}
                      sx={{
                        padding: '5px 10px',
                        marginBottom: 0,
                        borderRadius: 4,
                        backgroundColor: 'var(--color-light)',
                        color: 'rgb(8, 89, 80) !important',
                        fontWeight: 'bold',
                      }}
                      dir={relatedArticle.lang === 'ar' ? 'rtl' : 'ltr'}
                    >
                      <span className={'sub-titles-color'}  dir={'auto'}> {relatedArticle.published_at}</span>
                    </Typography>
                    <Tooltip title={'Read More'}>
                      <IconButton color="primary" href={relatedArticle.url} target="_blank">
                        <ArrowCircleRightOutlinedIcon
                          sx={{ fontSize: 28, stroke: '#979E9E', fill: '#979E9E', strokeWidth: '0px' }}
                        />
                      </IconButton>
                    </Tooltip>

                  </Box>
                  <CardContent>
                    <Link to={`/newsletters/${id}/NewsLetterDetail/${articleId}/show/${relatedArticle.id}`}>
                      <Typography
                        variant="h6"
                        component="div"
                        fontWeight="bold"
                        color="textPrimary"
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          color: '#000 !important',
                          fontSize: '20px',
                          textAlign: 'start',
                        }}
                        dir={relatedArticle.lang === 'ar' ? 'rtl' : 'ltr'}
                        gutterBottom
                      >
                        <a
                          href={relatedArticle.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                          dir={'auto'}
                        >
                          {relatedArticle.title}
                        </a>
                      </Typography>
                    </Link>
                  </CardContent>
                </CustomCardInfo>
              ))
            ) : (
              <CustomCardInfo sx={{ padding: 3, paddingBottom: 0, marginBottom: 1 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    fontWeight="bold"
                    color="textPrimary"
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      color: '#000 !important',
                      fontSize: '20px',
                      textAlign: 'start',
                    }}
                    gutterBottom
                  >
                    No Related Articles Found
                  </Typography>
                </CardContent>
              </CustomCardInfo>
            )}
          </Grid>
        </Grid>
      )}
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description scroll"
        sx={{ width: '70%', marginInline: 'auto', marginTop: 3 }}

      >
        <>
          <Box
            sx={{
            background: '#fff',
            borderRadius: '10px',
            outline: 'none',
             }}
            className='modal-large' >
            <Typography
              className="modal-modal-title d-flex justify-content-between align-items-baseline"
              variant="h6"
              component="h2"
              sx={{ display: 'flex', justifyContent: 'space-between'  ,overflowX:'auto'}}
            >
              Chat with {selectedCard?.title}
              <IconButton size="small" aria-label="close" color="inherit" mr={1} onClick={handleCloseChat}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
            <NewsletterChat document={selectedCard} type={'newsletter'} />
          </Box>
        </>
      </Modal>
    </Grid>
  );
};

export default SmolLMArticles;
