// src/modules/explore/messages.js
import { defineMessages } from 'react-intl';

// Pull the brand name from .env, default to "WAKEB" if missing
const brandName = process.env.REACT_APP_BRAND_NAME;

export default defineMessages({
  loading: {
    id: 'explore.loading',
    defaultMessage: 'Fetching data',
  },
  error: {
    id: 'explore.error',
    defaultMessage: 'Sorry, we’ve encountered an error while fetching the data. Please refresh the page and try again!',
  },
  getInTouch: {
    id: 'explore.getInTouch',
    defaultMessage: 'Get in touch',
  },
  chatLLM: {
    id: 'chatLLM',
    // Dynamically create the message using brandName
    defaultMessage: `${brandName} LLM`,
  },
  chatRAGOnline2: {
    id: 'chatRAGOnline2',
    defaultMessage: `${brandName} Live 2`,
  },
  chatRAGOnline: {
    id: 'chatRAGOnline',
    defaultMessage: `${brandName} Live`,
  },
  chatRAGOffline: {
    id: 'chatRAGOffline',
    defaultMessage: `${brandName} Local`,
  },
  chatFiles: {
    id: 'chatFiles',
    defaultMessage: `${brandName} Scanner`,
  },
  chatBrief7b: {
    id: 'chatBrief7b',
    defaultMessage: `${brandName} Brief 7b`,
  },
  chatBrief13b: {
    id: 'chatBrief13b',
    defaultMessage: `${brandName} Brief 13b`,
  },
});
