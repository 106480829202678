import React from 'react';
import { Box, Typography, FormControl, Select, MenuItem, Button, Tooltip } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadForm = ({
                                title = "Detect Fake Audio",
                                selectedLanguage,
                                handleLanguageChange,
                                keyFile,
                                handleFileChange,
                                fileInputRef,
                                acceptedFormats = "audio/*",
                                maxFileSize = "100MB",
                                supportedFormats = "WAV, MP3, OGG, FLAC",
                              }) => {
  return (
    <Box sx={{ mb: 4 }} >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 ,color:'#000 !important'}} className={'upload-title'}>
        {title}
      </Typography>

      <label style={{fontSize:'13px',color:"#000 !important"}}>
        Select a Model Language
      </label>

      <FormControl sx={{ width: "100%" }}>
        <Select
          id="language-select"
          value={selectedLanguage}
          onChange={handleLanguageChange}
          sx={{
            marginBottom: 2,
            minWidth: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d3daea",
              borderRadius: "8px",
            },
            '& .MuiInputBase-input': {
              color: '#909baf !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-primary)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-primary)',
            },
          }}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
        </Select>
      </FormControl>

      <Box
        sx={{
          border: "2px dashed #d3daea",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
          backgroundColor: "#f9fafb",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <Typography variant="body1" sx={{ mb: 1 }}>
          Click file to this area to upload
        </Typography>
        <Typography variant="caption" sx={{ color: "#6c757d" }}>
          Limit {maxFileSize} per file • {supportedFormats}
        </Typography>
        <div>
          <CloudUploadIcon sx={{ width: 35, height: 35 }} />
        </div>

        <Tooltip title={keyFile !== null ? 'Only one file can be uploaded' : ''}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              cursor: keyFile !== null ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
              '&.Mui-disabled': {
                pointerEvents: 'auto',
                opacity: 0.6,
              },
            }}
          >
            <Button
              component="label"
              variant="text"
              disabled={keyFile !== null}
              sx={{ color: "#fff !important", width: '100%', height: '100%' }}
            >
              <VisuallyHiddenInput
                ref={fileInputRef}
                type="file"
                accept={acceptedFormats}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default UploadForm;
