import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface BreadcrumbProps {
  items: Array<{ label: string; path?: string; isActive?: boolean }>;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const history = useHistory();

  const handleClick = (path: string) => {
    history.push(path);
  };

  return (
    <Box
      className="flex-wrap gap-4"
      sx={{ display: 'flex', alignItems: 'center', padding: '16px 10px', marginBottom: 1 }}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.isActive ? (
            <Typography color="textPrimary" sx={{ color: '#29abe2', fontWeight: 'bold' }}>
              {item.label}
            </Typography>
          ) : (
            <Typography
              onClick={() => handleClick(item.path || '#')}
              sx={{ textDecoration: 'none', cursor: 'pointer', color: '#29abe2' }}
            >
              {item.label}
            </Typography>
          )}
          {index < items.length - 1 && <Typography sx={{ margin: '0 8px' }}> / </Typography>}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Breadcrumb;
