import React from 'react';

interface UploadSpinnerProps {
  uploadLoading: boolean;
}

const UploadSpinner: React.FC<UploadSpinnerProps> = ({ uploadLoading }) => {
  // If not loading, return nothing
  if (!uploadLoading) return null;

  // Otherwise render the spinner and message
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '50px'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        {/* You can replace .custom-spinner with a real spinner or MUI's CircularProgress */}
        <div className="custom-spinner" />
        <div style={{ color: '#29abe2' }}>
           Uploading...
        </div>
      </div>
    </div>
  );
};

export default UploadSpinner;
