// @ts-nocheck
import Button from '@mui/joy/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ButtonSummary from '../ButtonSummary';
import Box from '@mui/joy/Box';

import * as React from 'react';
import { useEffect, useState } from 'react';
import axiosBackendApi from '../../../shared/services/api/axiosBackendInstance';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Alert, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import echo from '../../../echo';

const CHAT_URL = process.env.REACT_APP_CHAT_API;
export const FileTocComponent = ({ file, messages, token, userId, getFiles, updateFile }) => {
  const [fileErrors, setFileErrors] = useState({});
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [modalFileName, setModalFileName] = useState('');
  const [fileToDelete, setFileToDelete] = useState({
    name: null,
  });
  let handleOpenModal = false;

  const handleClosePreviewModal = () => setOpenPreviewModal(false);
  const handleOpenPreviewModal = () => setOpenPreviewModal(true);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const SEARCH_URL = process.env.REACT_APP_WEB_API;
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (!file.isUploaded) {
      uploadFile();
    }
  }, [file]);
  const formData = () => {
    let formData = new FormData();

    formData.set('part', file.parts - file.chunks.length);
    formData.set('is_last', file.chunks.length === 1);
    formData.set('filename', file.name);
    formData.set('file', file.chunks[0], `${file.name}.part`);

    return formData;
  };
  const channel = echo.channel('TableOfContentProcessChannel');

  useEffect(() => {
    const handleEvent = (data) => {
      if (file.toc_id === data.id) {
        file.toc = data.status === 100 ? data.summary_url : file.toc;
        file.message = data.message;
        file.status = data.status;
        updateFile(file)
      }
    };
    channel.listen('.TableOfContentProcessEvent', handleEvent);
    return () => {
      channel.stopListening('.TableOfContentProcessEvent', handleEvent);
    };
  }, []);

  const [tocError, setTocError] = useState(false);
  const removeFiles = (name) => {
    let data = {
      user_id: userId,
      file_name: file.name,
    };
    console.log(JSON.parse(localStorage.getItem('token')));
    axiosBackendApi
      .post(CHAT_URL + '/delete_user_files', data, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((response) => {
        getFiles();
      })
      .catch((error) => {
        getFiles();
      });
  };
  const [uploadProgress, setUploadProgress] = useState(0);
  const sendUploadedFiles = () => {
    if (file.chunks.length < 1) {
      return;
    }

    if (file.uploaded == file.parts && file.name != '') {
      return;
    }
    axiosBackendApi
      .post(SEARCH_URL + '/upload-chunks', formData(), {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setUploadProgress((file.uploaded / file.parts) * 100);
          file.uploaded += 1;
          file.name = response?.data?.filename || file.name;
          file.chunks.shift();
          if (file.chunks.length > 0) {
            sendUploadedFiles();
          }
          if (response?.data?.completed) {
            setUploadProgress(100);
            file.isUploaded = true;
            updateFile(file);
            file.url = response?.data?.file_url;
            summary(response?.data?.toc_id);
          }
        }
      })
      .catch((error) => {
        if (error.response?.status == 400) {
          setErrorMsg(error.response?.data?.errors?.error_message || 'Failed');
          // setErrorAlert(true);
        }
      });
  };

  const uploadFile = () => {
    let size = 512000;
    let chunks = Math.ceil(file.file.size / size);
    file.parts = chunks;
    for (let j = 0; j < chunks; j++) {
      file.chunks.push(file.file.slice(j * size, Math.min(j * size + size, file.file.size), file.file.type));
    }
    sendUploadedFiles();
  };
  const [tocUrl, setTocUrl] = useState('');

  const openFile = () => {
    if (file.url !== '') {
      setFileUrl(file.url);
      setTimeout(() => {
        handleOpenPreviewModal();
      }, 200);
    } else {
      // setErrorMsg("File url doesn't exist");
    }
  };
  const summary = async (toc_id) => {
    try {
      if (toc_id == undefined) {
        toc_id = file.toc_id;
      }
      const response = await axiosBackendApi.post(
        SEARCH_URL + '/toc',
        {
          user_id: userId,
          file_name: file.name,
          toc_id: toc_id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.hasOwnProperty('summary_url')) {
          file.toc = response.data.summary_url;
          setTocUrl(response.data.summary_url);
          setFileUrl(response.data.summary_url);
          setModalFileName(file.name);
          setTimeout(() => {
            handleOpenPreviewModal();
          }, 200);
        } else {
        }
      }
    } catch (error) {
      setTocError(true);
    }
  };

  const openSummary = () => {
    handleOpenModal = true;
    if (tocUrl == '') {
      summary();
    } else {
      console.log(tocUrl);
      setFileUrl(tocUrl);
      setModalFileName(file);
      setTimeout(() => {
        handleOpenPreviewModal();
      }, 200);
    }
  };

  const handleRetry = (fileName, index) => {
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [index]: false,
    }));
    summary();
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box className="file-upload-progress" sx={{ mt: 5 }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" color="success" {...props} />
        </Box>
        {props.value < 100 ? (
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    );
  }

  return (
    <>
      <li className="flex flex-col space-y-4 gap-3">
        <div className="d-flex align-items-center w-100">
          <span>{file.name.slice(0,40)}</span>
        </div>
        {file.isUploaded ? (
          <div className="d-flex file-btns space-x-4">
            <div>
              <Button
                variant=""
                title={messages.deleteFile}
                onClick={() => {
                  setOpenDialogDeleteFile(true);
                }}
                className="text-link Download delete"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="#f00"
                    d="M8.47 8.47a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 0 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 1 1-1.06-1.06L10.94 12L8.47 9.53a.75.75 0 0 1 0-1.06"
                  />
                  <path
                    fill="#f00"
                    fillRule="evenodd"
                    d="M7.317 3.769a42.5 42.5 0 0 1 9.366 0c1.827.204 3.302 1.643 3.516 3.48c.37 3.157.37 6.346 0 9.503c-.215 1.837-1.69 3.275-3.516 3.48a42.5 42.5 0 0 1-9.366 0c-1.827-.205-3.302-1.643-3.516-3.48a41 41 0 0 1 0-9.503c.214-1.837 1.69-3.276 3.516-3.48m9.2 1.49a41 41 0 0 0-9.034 0A2.486 2.486 0 0 0 5.29 7.424a39.4 39.4 0 0 0 0 9.154a2.486 2.486 0 0 0 2.193 2.164c2.977.332 6.057.332 9.034 0a2.486 2.486 0 0 0 2.192-2.164a39.4 39.4 0 0 0 0-9.154a2.486 2.486 0 0 0-2.192-2.163"
                    clipRule="evenodd"
                  />
                </svg>
              </Button>
            </div>
            <Dialog
              open={openDialogDeleteFile}
              onClose={() => setOpenDialogDeleteFile(false)}
              aria-labelledby="delete-dialog-title"
              aria-describedby="delete-dialog-description"
              sx={{ zIndex: 2000 }}
            >
              <DialogTitle id="delete-dialog-title">{'Confirm File Deletion'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-dialog-description">
                  Are you sure you want to delete this file?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialogDeleteFile(false)}
                  variant="outlined"
                  className="btn-default-outlined"
                >
                  {messages.Cancel}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    removeFiles();
                    setOpenDialogDeleteFile(false);
                  }}
                  className="btn-default danger"
                >
                  {messages.Delete}
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              variant=""
              className="text-link Download show"
              title={messages.showFile}
              onClick={() => {
                openFile();
              }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.60469 10.1233C7.60469 8.8363 8.59831 7.79301 9.82399 7.79301C11.0497 7.79301 12.0433 8.8363 12.0433 10.1233C12.0433 11.4102 11.0497 12.4535 9.82399 12.4535C8.59831 12.4535 7.60469 11.4102 7.60469 10.1233ZM9.05206 10.1233C9.05206 10.5709 9.39766 10.9338 9.82399 10.9338C10.2503 10.9338 10.5959 10.5709 10.5959 10.1233C10.5959 9.67563 10.2503 9.31275 9.82399 9.31275C9.39766 9.31275 9.05206 9.67563 9.05206 10.1233Z"
                  fill="#29abe2"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.3687 5.83762L18.4021 9.22156C18.6629 9.44076 18.8147 9.77251 18.8147 10.1233C18.8147 10.474 18.6629 10.8058 18.4021 11.025L14.3687 14.4089C11.7104 16.6369 7.93757 16.6369 5.27925 14.4089L1.24592 11.025C0.985118 10.8058 0.833313 10.474 0.833313 10.1233C0.833313 9.77251 0.985118 9.44076 1.24592 9.22156L5.27925 5.83762C7.93757 3.60963 11.7104 3.60963 14.3687 5.83762ZM6.17662 13.2742C8.31139 15.0582 11.3366 15.0582 13.4714 13.2742L17.1573 10.1233L13.4714 7.02301C11.3366 5.239 8.31139 5.239 6.17662 7.02301L2.49065 10.1233L6.17662 13.2742Z"
                  fill="#29abe2"
                />
              </svg>
            </Button>

            <ButtonSummary
              file={file}
              tocError={tocError}
              openSummary={!tocError ? handleRetry : openSummary}
              messages={messages}
              className="summary"
              index={0}
            />
          </div>
        ) : (
          <LinearProgressWithLabel className="mt-10" value={Math.round(uploadProgress)} />
        )}
      </li>
      <Modal
        open={openPreviewModal}
        onClose={handleClosePreviewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          position: 'absolute',
          zIndex: '99999 !important',
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 60px)',
          }}
        >
          <Typography
            className="modal-modal-title d-flex justify-content-between align-items-baseline"
            variant="h6"
            component="h2"
          >
            {file.name}
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClosePreviewModal} ml={1}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
          <div className="preview-content" sx={{ mt: 2 }}>
            <object className="text-tag" width="100%" height="100%" data={fileUrl}>
              <p>
                {messages.UnableToDisplayTheFile}
                <a href={fileUrl}>{messages.withDownload}</a>
                {messages.instead}
              </p>
            </object>
          </div>
        </Box>
      </Modal>
    </>
  );
};
