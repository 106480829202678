// @ts-nocheck
import { useEffect, useReducer, useState } from 'react';

import { TitleTagSize } from '../../shared/components/titleTag/titleTag.types';

import { Container, TitleTag } from './newsLetter.styles';

import * as React from 'react';
import axios from 'axios';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { string } from 'prop-types';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { ROUTES } from '../app.constants';
import AddIcon from '@mui/icons-material/Add';
import { CreateNewFolder } from '@mui/icons-material';
import { CreateNewsLetter } from './create.component';
import { EditNewsLetter } from './edit.component';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NoDataUrl from '../../images/minstry_of_defense/folder-empty-svgrepo-com.svg';
import SearchUrl from '../../images/search.gif';
// import SearchLoadingImg from '../../images/loading.gif';
import Snackbar from '@mui/material/Snackbar';
import Pagination from '@mui/material/Pagination';

const SEARCH_URL = process.env.REACT_APP_WEB_API;
const SearchLoadingImg = process.env.REACT_APP_LOADER_URL;
function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const style = {
  position: 'absolute',
  top: '30px',
  left: '30px',
  // transform: 'translate(0%, -10%)',
  width: 'calc(100% - 60px)',
  // bgcolor: 'background.paper',
  bgcolor: '#333',
  border: '2px solid rgba(0,0,0, 0.5)',
  boxShadow: 24,
  p: 2,
  borderRadius: '8px',
};

export const UnsubscribeNewsletter = () => {
  return (
    <Container>
      <TitleTag size={TitleTagSize.SMALL} withLogo withCompanyText locale={locale} messages={messages} />

      <div className="search-container">
        <div className="w-100">
          <div className="search-top">
            <div className="d-flex flex-gap-regular align-items-center">
              <div className="page-title">Unsubscribe Newsletter</div>
            </div>
          </div>
          <div className="position-relative news-letter page">
            <br />
            <div className="data-loading">
              <div className="no-data-cont">
                <img src={NoDataUrl} className="no-data" />
                تم الغاء الاشتراك بنجاح
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
