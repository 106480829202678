import React from 'react';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { divide } from 'ramda';

const ButtonDetection = ({
                           onClick,
                           loading,
                           disabled,
                           keyFile
                         }) => {
  return (
    <Tooltip title={'Ai Detect'}>
    <button
      onClick={onClick}
      disabled={disabled || keyFile || loading}
     className={'detection-button'}
    >
      <span style={{ display: 'flex', alignItems: 'center' , color: '#fff !important'}} className={'detection'}>
        Ai Detect
        {loading && <div className="custom-spinner"></div>}
      </span>
    </button>
  </Tooltip>

  );
};

export default ButtonDetection;
