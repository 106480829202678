// @ts-nocheck
// @ts-ignore
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './shared/services/sentry';
// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import { Provider } from 'react-redux';
import { Router } from 'react-router';
// @ts-ignore
import FontFaceObserver from 'fontfaceobserver';
import 'normalize.css/normalize.css';
import './theme/global';
import './assets/scss/main.scss';
import TagManager from 'react-gtm-module';
import configureStore from './config/store';
import browserHistory from './shared/utils/history';
import { LanguageProvider } from './modules/lang/lang.context';

// 1) Font Loading Observer
const openSansObserver = new FontFaceObserver('Open Sans', {});
openSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  }
);

// 2) Configure Redux store
const initialState = {};
const store = configureStore(initialState);
const queryClient = new QueryClient();

// 3) Google Tag Manager (if any)
if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

/*
  4)
  Map each desired CSS variable
  to the corresponding environment variable
*/
const envToCssVarMap = {
  // Basic Colors
  '--color-primary': 'REACT_APP_COLOR_PRIMARY',
  '--color-secondary': 'REACT_APP_COLOR_SECONDARY',
  '--color-light': 'REACT_APP_COLOR_LIGHT',

  // Status Colors
  '--status-color-starting': 'REACT_APP_STATUS_COLOR_STARTING',
  '--status-color-waiting': 'REACT_APP_STATUS_COLOR_WAITING',
  '--status-color-searching': 'REACT_APP_STATUS_COLOR_SEARCHING',
  '--status-color-generating-outline': 'REACT_APP_STATUS_COLOR_GENERATING_OUTLINE',
  '--status-color-generating-article': 'REACT_APP_STATUS_COLOR_GENERATING_ARTICLE',
  '--status-color-polishing': 'REACT_APP_STATUS_COLOR_POLISHING',
  '--status-color-preparing': 'REACT_APP_STATUS_COLOR_PREPARING',
  '--status-color-completed': 'REACT_APP_STATUS_COLOR_COMPLETED',
  '--status-color-error': 'REACT_APP_STATUS_COLOR_ERROR',
  '--status-color-not-submitted': 'REACT_APP_STATUS_COLOR_NOT_SUBMITTED',

  // AM5 Color Series
  '--brand-color-1': 'REACT_APP_BRAND_COLOR_1',
  '--brand-color-2': 'REACT_APP_BRAND_COLOR_2',
  '--brand-color-3': 'REACT_APP_BRAND_COLOR_3',
  '--brand-color-4': 'REACT_APP_BRAND_COLOR_4',
  '--brand-color-5': 'REACT_APP_BRAND_COLOR_5',
  '--brand-color-6': 'REACT_APP_BRAND_COLOR_6',
  '--brand-color-7': 'REACT_APP_BRAND_COLOR_7',
  '--brand-color-8': 'REACT_APP_BRAND_COLOR_8',
  '--brand-color-9': 'REACT_APP_BRAND_COLOR_9',
  '--brand-color-10': 'REACT_APP_BRAND_COLOR_10',
  '--brand-color-11': 'REACT_APP_BRAND_COLOR_11',
  '--brand-color-12': 'REACT_APP_BRAND_COLOR_12',
  '--brand-color-13': 'REACT_APP_BRAND_COLOR_13',
  '--brand-color-14': 'REACT_APP_BRAND_COLOR_14',
  '--brand-color-15': 'REACT_APP_BRAND_COLOR_15',
  '--brand-color-16': 'REACT_APP_BRAND_COLOR_16',
  '--brand-color-17': 'REACT_APP_BRAND_COLOR_17',
  '--brand-color-18': 'REACT_APP_BRAND_COLOR_18',
  '--brand-color-19': 'REACT_APP_BRAND_COLOR_19',
  '--brand-color-20': 'REACT_APP_BRAND_COLOR_20',
  '--brand-color-21': 'REACT_APP_BRAND_COLOR_21',

  // Chart Colors
  '--chart-color-1': 'REACT_APP_CHART_COLOR_1',
  '--chart-color-2': 'REACT_APP_CHART_COLOR_2',
  '--chart-color-3': 'REACT_APP_CHART_COLOR_3',

  // Filters
  '--gif-filter': 'REACT_APP_GIF_FILTER',

};

// 5) Inject environment variables as CSS custom properties
Object.entries(envToCssVarMap).forEach(([cssVar, envVarName]) => {
  const envValue = process.env[envVarName];
  if (envValue) {
    document.documentElement.style.setProperty(cssVar, envValue);
  }
});
/**
 * 6) Dynamically set the favicon from .env
 *
 * Make sure your .env has something like:
 *    REACT_APP_FAVICON_URL="/icons/favicon_gadd.ico"
 * or any custom path or full URL.
 */
function setFavicon() {
  const faviconURL = process.env.REACT_APP_FAVICON_URL;
  if (!faviconURL) return; // No favicon in .env? Do nothing.

  let link = document.querySelector('link[rel="shortcut icon"]');
  if (!link) {
    link = document.createElement('link');
    link.rel = 'shortcut icon';
    document.head.appendChild(link);
  }
  link.href = faviconURL;
}
setFavicon();
// 6) Render function
const render = (): void => {
  // Dynamically require your main routes component
  const NextApp = require('./routes').default;
  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <NextApp />
          </LanguageProvider>
        </QueryClientProvider>
      </Router>
    </Provider>,
    document.getElementById('app')
  );
};

// 7) initApp function
const initApp = async (): Promise<void> => {
  // Chunked polyfill for browsers without Intl support
  if (!window.Intl) {
    new Promise((resolve) => {
      resolve(require('intl'));
    })
      .then(() =>
        Promise.all([
          require('intl/locale-data/jsonp/en.js'),
          require('intl/locale-data/jsonp/pl.js'),
        ])
      )
      .then(() => render())
      .catch((err) => {
        throw err;
      });
  } else {
    render();
  }
};

initApp();
