// @ts-nocheck
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import sendIcon from '../../images/sendArrow.svg';
import AudioRecorder from './audioRecorder';
import SelectModel from './SelectModel';
import LanguageButton from './languageDropDown';
import chatMessages from '../chat/components/chat.messages';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ButtonSummary from './ButtonSummary';
import {
  createSession as createSessionStore,
  newLog,
  newQuestion,
  setLogId,
} from '../../store/actions/sessionsActions';
import { ToastRenderer, useToast } from '../../shared/components/toast';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
// import SearchLoadingImg from '../../images/loading.gif';
import { useAdminPanelContext } from '../../shared/components/adminPanel/adminPanelContext';
import echo from '../../echo';
import { FileTocComponent } from './toc/file.component';

const CHAT_URL = process.env.REACT_APP_CHAT_API;
const SEARCH_URL = process.env.REACT_APP_WEB_API;
const RAGONLINE_URL = process.env.REACT_APP_RAG_ONLINE_API;
let handleOpenModal = false;
let token = '';
const SearchLoadingImg = process.env.REACT_APP_LOADER_URL;
export default function ExampleTextareaComment({ messages }) {
  const { logout } = useAdminPanelContext();
  const toast = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const questionparam = queryParams.get('question');

  const [fileErrors, setFileErrors] = useState({});
  const [anchorElRemoveAll, setAnchorElRemoveAll] = React.useState<HTMLButtonElement | null>(null);
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState('normal');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [chatInputDisabled, setChatInputDisabled] = useState(false);
  const [question, setQuestion] = useState(type === 'live' ? questionparam : '');

  const [model, setModel] = useState(type === 'live' ? 'chatRAGOnline' : 'chatLLM');

  const [inputDisabled, setInputDisabled] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const [streaming, setStreaming] = useState(false);
  const [signal, setSignal] = useState(controller.signal);
  const [showIcons, setShowIcons] = useState(false);
  const [showStopResponse, setShowStopResponse] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [answer, setAnswer] = useState('');
  const [chatting, setChatting] = useState(false);
  const intl = useIntl();
  const [isInitial, setIsInitial] = useState(true);
  const [userId, setUserId] = useState();
  const [openRemoveAll, setOpenRemoveAll] = useState(false);
  const [modalFileName, setModalFileName] = React.useState('');
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = React.useState(false);
  const [dialogFileToDelete, setDialogFileToDelete] = React.useState({ name: null, index: 0 });
  const [dialogFileToDeleteFile, setDialogFileToDeleteFile] = React.useState({ name: null, index: 0 });
  const [selectedVoiceLang, setSelectedVoiceLang] = useState('Auto');
  const [user, setUser] = useState<AdminPanelUser | null>(() => {
    const localData = localStorage.getItem('user');
    return localData ? JSON.parse(localData) : [];
  });
  const handleOpenDialog = (fileName, index) => {
    setDialogFileToDelete({ name: fileName, index });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogFile = () => {
    setDialogFileToDelete(false);
  };

  const confirmDelete = () => {
    const { name, index } = dialogFileToDelete;
    if (name) {
      setFileToDelete({ name: null, i: 0 });
      removeFiles();
    } else {
      // Handle bulk delete if needed
    }
    handleCloseDialog();
  };
  const confirmDeleteFile = () => {
    const { name, index } = dialogFileToDeleteFile;
    if (name) {
      setFileToDeleteFile({ name: null, i: 0 });
      // removeFiles();
    } else {
      // Handle bulk delete if needed
    }
    handleCloseDialogFile();
  };

  const handleOpenPreviewModal = () => setOpenPreviewModal(true);
  const handleClosePreviewModal = () => setOpenPreviewModal(false);
  const handleCloseRemoveAll = () => {
    setAnchorElRemoveAll(null);
    setOpenRemoveAll(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    {
      value: 'chatLLM',
      selected: true,
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatLLM)}</span>,
    },
    {
      value: 'chatRAGOnline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOnline)}</span>,
    },

    {
      value: 'chatRAGOffline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOffline)}</span>,
    },
    {
      value: 'chatBrief7b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief7b)}</span>,
    },
    {
      value: 'chatBrief13b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief13b)}</span>,
    },
    {
      value: 'chatFiles',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatFiles)}</span>,
    },
  ];
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' },
    {
      value: 'fr',
      label: 'French',
    },
    { value: 'es', label: 'Spanish' }, // Add more languages as needed
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    token = user.token;
    setUserId(user.id);
    if (id != undefined) {
      setSessionId(id);
      setIsInitial(false);
    }
    // getFiles();
    if (id === undefined) {
      setAnswer('');
      setChatting(false);
      setIsInitial(true);
    }
    return () => {};
  }, [id]);

  useEffect(() => {
    if (chatting) {
      handleFetchData();
    }
  }, [chatting]);

  useEffect(() => {
    if (answer != '') {
      storeLog(sessionId);
    }
  }, [answer]);
  const createSession = async () => {
    try {
      let data = {
        title: question.substr(0, 50),
      };
      const session = await dispatch(createSessionStore(data)).unwrap();
      setIsInitial(false);
      setSessionId(session.id);
      return session;
    } catch (e) {
    } finally {
    }
    return id;
  };

  const send = async () => {
    if (question && question.trim() != '') {
      dispatch(newQuestion(question));
      if (isInitial) {
        await createSession();
      }
      setChatting(true);
    }
  };

  const handleFetchData = () => {
    if (model == chatMessages.chatLLM.id) {
      fetchData('/chat');
    } else if (model == chatMessages.chatRAGOnline.id) {
      fetchData('/rag_online2');
    } else if (model == chatMessages.chatRAGOffline.id) {
      fetchData('/rag_mongodb');
    } else if (model == chatMessages.chatBrief7b.id) {
      fetchData('/llama_summarize_7b');
    } else if (model == chatMessages.chatBrief13b.id) {
      fetchData('/llama_summarize_13b');
    } else if (model == chatMessages.chatFiles.id) {
      fetchData('/rag_pdf');
    }
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box className="file-upload-progress" sx={{mt: 5}}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" color="success" {...props} />
        </Box>
        {props.value < 100 ? (
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    );
  }
  const [sources, setSources] = useState([]);

  const getStreamUrl = () => {
    const baseUrl =  CHAT_URL + '/rag_online2';

    const params = new URLSearchParams({
      query: question,
      session_id: sessionId,
    });

    return `${baseUrl}?${params.toString()}`;
  };

  const fetchData = async (chatUrl) => {
    setChatting(true);
    setInputDisabled(true);
    if (model === 'chatRAGOnline') {
      setStreaming(true);

      setAnswer('');
      const eventSource = new EventSource(getStreamUrl());

      let chunks = '';

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        if (newMessage['type'] == "finished") {
          eventSource.close();
          setShowIcons(true);
          setAnswer(chunks);
          setInputDisabled(false);
          setChatting(false);
          return;
        }
        else if (newMessage['type'] == "error") {
          chunks += newMessage['data'];
          eventSource.close();
          setShowIcons(true);
          setAnswer(chunks);
          setInputDisabled(false);
          setChatting(false);
          return;
        }
        else {
          if (newMessage['type'] === 'llm') {
            chunks += newMessage['text'];
            let model = 'chatRAGOnline';
            dispatch(newLog({ chunks, model }));
          } else if(newMessage['type'] === 'sources') {
            let sources = newMessage['data']['organic'];
            setSources(sources);
            dispatch(newLog({ sources, model }));
          }
        }

      };
      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close();
      };
    }
    else {
      setSources([]);
      let payload = {};
      const localData = localStorage.getItem('user');
      const userData = JSON.parse(localData);
      payload = { user_id: userData.id, query: question, session_id: sessionId };
      let stopChunks = false;
      let url = CHAT_URL + chatUrl;

      setStreaming(true);
      try {
        const myResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify(payload),
          signal: signal,
        });
        if (myResponse.status === 401) {
          logout();
        }
        if (!myResponse.ok) {
          const errorData = await myResponse.json();
        }
        const reader = myResponse.body.getReader();
        // setLoading(false);
        let chunks = '';

        setAnswer('');
        reader.read().then(function processText({ done, value }) {
          setShowStopResponse(true);
          let chunk = new TextDecoder('utf-8').decode(value);
          if (done) {
            setShowIcons(true);
            setAnswer(chunks);
            setInputDisabled(false);
            setChatting(false);
            return;
          }
          if (stopChunks) {
            setShowIcons(true);
            setAnswer(chunks);
            setChatting(false);
            setInputDisabled(false);
            return;
          }
          if (chunk === '### SEARCHING' || chunk === '### PROCESSING') {
            chunks = chunk;
          } else {
            if (chunks === '### PROCESSING') {
              chunks = chunk;
            } else {
              chunks += chunk.replace(/```/g, '\n\n ``` \n\n');
              chunks = chunks.replace(/### SEARCHING/g, '');
              chunks = chunks.replace(/### PROCESSING/g, '');
            }
          }
          dispatch(newLog({ chunks, model }));
          return reader.read().then(processText);
        });
      } catch (error) {
      } finally {
      }
    }
  };
  const storeLog = async (session_id) => {
    let data = {
      question: question,
      answer: answer,
      sources:sources,
      session_id: session_id,
      model,
    };

    try {
      const response = await axiosBackendApi.post(SEARCH_URL + '/user/session/log', data);
      if (response.status === 200) {
        const log = response.data.session;
        dispatch(setLogId({ log }));
        history.push(`/chat/${session_id}`);
      }
      setQuestion('');
    } catch (error) {
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Request data:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !inputDisabled) {
      send();
    }
  };
  const formData = (i) => {
    let formData = new FormData();

    formData.set('part', files[i].parts - files[i].chunks.length);
    formData.set('is_last', files[i].chunks.length === 1);
    formData.set('filename', files[i].name);
    formData.set('file', files[i].chunks[0], `${files[i].name}.part`);

    return formData;
  };

  const [drawerOpen, setDrawerOpen] = useState(() => {
    const saved = localStorage.getItem('drawerOpen');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [errorMsg, setErrorMsg] = React.useState('error');
  const [errorAlert, setErrorAlert] = React.useState(false);
  const handleCloseError = () => setErrorAlert(false);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseError}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseError}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const closeErrorAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorAlert(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'file/pdf': ['.pdf'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: (files) => {
      upload(files);
    },
  });
  const upload = async (_files) => {
    const fileSizeMB = _files[0]?.size / 1024 / 1024;

    // Check if the file is larger than 5 MB
    if (fileSizeMB > 50) {
      setFileError(true);
      handleOpenModal = true;
      return;
    }

    if (fileSizeMB < 100 && !files.some((obj) => obj.name === _files[0].name)) {
      setFileError(false);
      handleOpenModal = false;
      let i = files.length;

      // Add file
      setFiles((prevFiles) => {
        return [
          ...prevFiles,
          {
            file: _files[0],
            name: _files[0].name,
            parts: 0,
            chunks: [],
            uploaded: 0,
            isUploaded: false,
            toc: null,
            status: 1,
            tocError: false,

          },
        ];
      });
      // files.push({
      //   file: _files[0],
      //   name: _files[0].name,
      //   parts: 0,
      //   chunks: [],
      //   uploaded: 0,
      //   toc: null,
      //   status: 1,
      //   tocError: false,
      // });

      // Creating chunks
      // let size = 512000;
      // let chunks = Math.ceil(files[i].file.size / size);
      // files[i].parts = chunks;
      //
      // for (let j = 0; j < chunks; j++) {
      //   files[i].chunks.push(
      //     files[i].file.slice(j * size, Math.min(j * size + size, files[i].file.size), files[i].file.type)
      //   );
      // }
      // scrollToBottom();
      // // Upload
      // sendUploadedFiles(i);
    } else {
      scrollToBottom();
      // Optionally, set file error for other conditions
      // setFileError(true);
    }
  };


  const getFiles = async () => {
    setIsLoading(true); // Start loading
    const localData = localStorage.getItem('user');
    const userData = JSON.parse(localData);
    const token = userData?.token;
    try {
      const response = await axiosBackendApi.post(
        `${SEARCH_URL}/get_file_names`,
        { user_id: userData.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const newFiles = response?.data?.map((file) => ({
        file: {},
        name: file.file_name,
        parts: 0,
        chunks: [],
        toc_id: file.toc_id,
        status: file.status ?? 1,
        uploaded: 0,
        isUploaded : true,
        toc: '',
        url: file.file_url,
      }));
      setFiles(newFiles);
    } catch (error) {
      console.error('Failed to fetch files:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const [fileToDelete, setFileToDelete] = useState({
    name: null,
    i: 0,
  });
  const [fileToDeleteFile, setFileToDeleteFile] = useState({
    name: null,
    i: 0,
  });
  const handleDeleteFile = (fileName, index) => {
    setFileToDelete({ name: fileName, i: index });
  };
  const removeFiles = (name) => {
    setFileError(false);
    handleClose();
    handleCloseRemoveAll();
    // setFiles([])
    let data = {};
    if (name) {
      data = {
        user_id: userId,
        file_name: name,
      };
    } else {
      data = {
        user_id: userId,
      };
    }
    axiosBackendApi
      .post(CHAT_URL + '/delete_user_files', data, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        getFiles();
      })
      .catch((error) => {
        getFiles();
      });
  };

  const sendUploadedFiles = (i) => {
    if (files[i].chunks.length < 1) {
      return;
    }

    if (files[i].uploaded == files[i].parts && files[i].name != '') {
      return;
    }

    // SEARCH_URL
    axiosBackendApi
      .post(SEARCH_URL + '/upload-chunks', formData(i), {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          files[i].uploaded += 1;
          files[i].name = response?.data?.filename || files[i].name;

          files[i].chunks.shift();
          if (files[i].chunks.length > 0) {
            sendUploadedFiles(i);
          }

          if (response?.data?.completed) {
            setFiles((prevArr) => {
              const result = [...prevArr];
              result[i].url = response?.data?.file_url;
              return result;
            });
            // send to summarize TOC // files[i].toc
            summary(files[i].name, i, response?.data.toc_id);
          }
        }
      })
      .catch((error) => {
        if (error.response?.status == 400) {
          setErrorMsg(error.response?.data?.errors?.error_message || 'Failed');
          // setErrorAlert(true);
        }
        files.splice(i, 1);
      });
  };
  const [tocError, setTocError] = useState(false); // State to handle TOC error

  const summary = async (fileName, i, toc_id) => {
    try {
      if (files[i].toc_id != undefined) {
        toc_id = files[i].toc_id
      }
      const response = await axiosBackendApi.post(
        SEARCH_URL + '/toc',
        {
          user_id: userId,
          file_name: fileName,
          toc_id: toc_id
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status === 200) {
        setFiles((prevArr) => {
          const result = [...prevArr];
          result[i].toc = response.data.summary_url;
          return result;
        });
        if (handleOpenModal) {
          setFileUrl(files[i].toc);
          setModalFileName(fileName);
          setTimeout(() => {
            handleOpenPreviewModal();
          }, 200);
        }
      }
    } catch (error) {
      console.error(error);
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [i]: true,
      }));
      setFiles((prevArr) => {
        const result = [...prevArr];
        result[i].toc = '';
        return result;
      });
    }
  };
  const handleRetry = (fileName, index) => {
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [index]: false,
    }));
    summary(fileName, index);
  };
  const filesRef = useRef(null);
  const [fileUrl, setFileUrl] = React.useState('');
  const scrollToBottom = () => {
    filesRef?.current?.addEventListener('DOMNodeInserted', (e) => {
      e.currentTarget.scroll({
        top: e.currentTarget.scrollHeight,
        behavior: 'smooth',
      });
    });
  };
  const handleClickRemoveAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRemoveAll(event.currentTarget);
    setOpenDialog(true);
  };
  const openSummary = (fileName, i) => {
    handleOpenModal = true;
    if (files[i].toc == '') {
      setFiles((prevArr) => {
        const result = [...prevArr];
        result[i].toc = null;
        return result;
      });
      console.log(files[i]);
      summary(fileName, i); // if success open modal;
    } else {
      setFileUrl(files[i].toc);
      setModalFileName(fileName);
      setTimeout(() => {
        handleOpenPreviewModal();
      }, 200);
    }
  };
  const toggleDrawer = () => {
    setDrawerOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem('drawerOpen', JSON.stringify(newState)); // Save the new state to local storage
      return newState;
    });
  };

  // Effect to synchronize drawerOpen state with local storage
  useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(drawerOpen));
  }, [model]);
  const drawers = () => {
    return (
      <div className={drawerOpen ? 'drawer-open drawer' : 'drawer'} style={{ zIndex: -999 }}>
        {/* Upload / Dropzone area */}
        <div className="d-flex align-items-center justify-content-between">
          <Typography variant="h4" className="drawer-title">
            {/*{messages.UploadFiles}*/}
            UploadFiles
          </Typography>

          <CloseIcon onClick={() => setDrawerOpen(false)} className="cursor-pointer close-files" />
        </div>
        <div {...getRootProps()} className="upload-block mb-2">
          <input {...getInputProps()} />
          <CloudUploadIcon className="color-main" />
          <p mt={1}>
            {/*{messages.DragAndDrop}*/}
            Drag and drop files here or click to browse.
            <br />
            <span className={'max-size'}>Maximum file upload size is 50 MB.</span>
          </p>
        </div>
        {/* Files list */}
        <ul className="files-list scroll" ref={filesRef}>
          {isLoading && (
            <div className="data-loading">
              <img src={SearchLoadingImg} className="loading-img" />
            </div>
          )}
          {files.length ? (
            <div className="d-flex justify-content-end">
              {/*<DeleteForeverIcon className="remove-files"/>*/}
              <Typography
                variant="body1"
                color="error"
                className="remove-files"
                onClick={(event) => {
                  setFileToDelete({ name: null, i: 0 });
                  handleClickRemoveAll(event);
                }}
              >
                Remove all
              </Typography>
              <div>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="delete-dialog-title"
                  aria-describedby="delete-dialog-description"
                  className={'dialog_layer'}
                >
                  <DialogTitle id="delete-dialog-title">{'Confirm File Deletion'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                      Are you sure you want to delete this file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" className="btn-default-outlined" onClick={handleCloseDialog} autoFocus>
                      {messages.Cancel}
                    </Button>
                    <Button
                      className="btn-default danger"
                      onClick={() => {
                        setFileToDelete({ name: null, i: 0 });
                        removeFiles();
                        handleCloseDialog();
                      }}
                      variant="contained"
                      autoFocus
                    >
                      {messages.Delete}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : (
            !isLoading && (
              <Typography variant="body2" color="text.secondary" sx={{ color: '#eee' }}>
                No files to display.
              </Typography>
            )
          )}

          {files.map((file, index) => (
            <FileTocComponent
              key={file.id || index}
              file={file}
              messages={messages}
              token={token}
              userId={userId}
              getFiles={getFiles}
              updateFile={(updatedFile) =>
                setFiles((prevFiles) =>
                  prevFiles.map((f, idx) => (idx === index ? updatedFile : f))
                )
              }
            />
          ))}

          {fileError ? (
            <div className="warning-alert">
              <Alert
                severity="error"
                onClose={() => {
                  setFileError(false);
                }}
              >
                {messages.TheFileLarge}
              </Alert>
            </div>
          ) : (
            ''
          )}
        </ul>
      </div>
    );
  };
  const changeModel = (model) => {
    if (model === 'chatFiles') {
      handelFileDrawer();
    } else {
      setDrawerOpen(false);
    }
    setModel(model);
  };
  const [hasFilesBeenFetched, setHasFilesBeenFetched] = useState(false);
  const handelFileDrawer = () => {
    toggleDrawer();
    if (!hasFilesBeenFetched) {
      getFiles();
      setHasFilesBeenFetched(true); // Update the state to prevent future fetches
    }
  };

  function handleAddAudio(base64data) {
    axiosBackendApi
      .post(
        CHAT_URL + '/stt',
        {
          user_id: userId,
          audio: base64data,
          lang: selectedVoiceLang,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        setQuestion(response.data.text);
      })
      .catch((error) => {});
  }

  return (
    <div className="chatInput">
      <FormControl className="chatInput__form">
        <Textarea
          onKeyPress={onEnterPress}
          className="chatMessage"
          placeholder="How can I help you?"
          minRows={3}
          // onKeyDown={(e) => e.key === 'Enter' && send}
          disabled={chatInputDisabled}
          className={'textarea'}
          endDecorator={
            <Box className="flex items-center justify-between w-full chatInput__control">
              <div className={'files-upload'}>
                <SelectModel onSelectModel={changeModel} variant="soft" className="chatCategory" options={options} />
                {(model == chatMessages.chatFiles.id || localStorage.getItem('selectedModel') === 'chatFiles') && (
                  <div
                    id="uploadFileBtn"
                    title="Upload File"
                    onClick={handelFileDrawer}
                    className={localStorage.getItem('selectedModel') === 'chatFiles' ? 'drawer-open' : ''}
                    style={{
                      marginInlineEnd: '1rem',
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M16.4002 8.9502L10.1575 14.9815C8.19991 16.8728 5.02594 16.8728 3.06832 14.9815C1.10351 13.0832 1.11177 10.003 3.08675 8.11466L8.84774 2.57055C10.1872 1.27646 12.3588 1.27645 13.6983 2.57052C15.0426 3.86934 15.0369 5.97682 13.6856 7.26891L7.85982 12.8642C7.13676 13.5628 5.96437 13.5628 5.24127 12.8642C4.51816 12.1656 4.51816 11.0329 5.24128 10.3343L10.0656 5.67336"
                        stroke="#ACB4C0"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                  }}
                >
                  <AudioRecorder handleAddAudio={handleAddAudio} />
                  <LanguageButton selectedVoiceLang={selectedVoiceLang} setSelectedVoiceLang={setSelectedVoiceLang} />
                </div>
              </div>

              <Tooltip title="Send Message" placement="left">
                <Button
                  className="btn__primary"
                  sx={{
                    marginInlineStart: 'auto',
                  }}
                  onClick={send}
                  disabled={inputDisabled}
                >
                  <img src={sendIcon} width={10} className={'send'} />
                  <span>Send message</span>
                </Button>
              </Tooltip>
            </Box>
          }
          sx={{
            minWidth: 300,
            fontWeight,
            fontStyle: italic ? 'italic' : 'initial',
          }}
          value={chatting ? '' : question}
          onChange={(e) => {
            // console.log(e.target.value + 5);
            if (e.target.value !== '\n') {
              setQuestion(e.target.value);
            }
          }}
        />
        {drawers()}

        <Snackbar
          open={errorAlert}
          autoHideDuration={1500}
          onClose={closeErrorAlert}
          message={errorMsg}
          action={action}
          className="alert-error"
          severity="error"
          variant="filled"
        />
      </FormControl>
      <ToastRenderer />
    </div>
  );
}
