import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Card,
  IconButton,
  TextField,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import DeleteIcon from '@mui/icons-material/Delete';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';

import { styled } from '@mui/material/styles';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';

// Common UI components
import UploadForm from './components/uploadForm';
import ResultPercentage from './components/resultPercentage';
import ButtonDetection from './components/buttonDetection';
import Message from './components/message';
import UploadSpinner from './components/uploaderSpinner';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// For tab usage
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Video = () => {
  // -------------------------------
  // State
  // -------------------------------
  const [language, setLanguage] = useState('');
  const [loading, setLoading] = useState(false);

  // Local file states
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoFileSource, setVideoFileSource] = useState('');

  // YouTube
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [tabValue, setTabValue] = useState(0);

  // Check box: analyze both audio & video (media) or just video
  const [analyzeAudio, setAnalyzeAudio] = useState(false);

  // We keep separate states for audio and video results:
  const [predictionsAudio, setPredictionsAudio] = useState<{
    ensemble_label?: string;
    ensemble_confidence?: number;
  } | null>(null);
  const [uploadLoading, setUploadLoading] = useState(false); // For file upload spinner
  const [predictionsVideo, setPredictionsVideo] = useState<{
    ensemble_label?: string;
    ensemble_confidence?: number;
  } | null>(null);

  // For toasts / messages
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  // Refs
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Allowed video types
  const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];

  // -------------------------------
  // Handlers / Helpers
  // -------------------------------
  const showMessage = (newMessage: string) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // -------------------------------
  // File Upload
  // -------------------------------
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!allowedVideoTypes.includes(file.type)) {
        showMessage('Invalid file type. Please upload a valid video file.');
        return;
      }

      setVideoFile(file);

      // Attempt to upload the file to the backend
      const formData = new FormData();
      formData.append('file', file);

      setUploadLoading(true);
      try {
        // Suppose we have an endpoint "video/upload" that returns { data: "URL_TO_VIDEO" }
        const response = await axiosBackendApi.post('video/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setVideoFileSource(response.data.data || '');
      } catch (error) {
        console.error('File upload failed', error);
        showMessage('File upload failed');
        setUploadLoading(false);

      } finally {
        setUploadLoading(false);
      }
    }
  };

  const handleRemove = () => {
    setVideoFile(null);
    setVideoFileSource('');
    setPredictionsAudio(null);
    setPredictionsVideo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // -------------------------------
  // Submit for Detection
  // -------------------------------
  /**
   * sendData: file-based detection
   * If analyzeAudio==true => call /media (both audio & video analysis)
   * Otherwise => call /video (video only)
   */
  const sendData = async () => {
    if (!language) {
      showMessage('Please select a model language.');
      return;
    }

    if (!videoFileSource) {
      showMessage('Please upload a video file first.');
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', videoFileSource);
      formData.append('selected_language', language);

      let response;
      if (analyzeAudio) {
        // Call the "media" endpoint => returns { audio_analysis, video_analysis }
        response = await axiosBackendApi.post('media', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Audio analysis: rename Prob => ensemble_confidence for consistency
        const audioRes = response.data.audio_analysis;
        const videoRes = response.data.video_analysis;
        setPredictionsAudio({
          ensemble_label: audioRes?.ensemble_label,
          ensemble_confidence: audioRes?.Prob,
        });
        setPredictionsVideo({
          ensemble_label: videoRes?.ensemble_label,
          ensemble_confidence: videoRes?.ensemble_confidence,
        });
      } else {
        // Call the "video" endpoint => returns { ensemble_label, ensemble_confidence }
        response = await axiosBackendApi.post('video', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setPredictionsAudio(null); // No audio result
        setPredictionsVideo({
          ensemble_label: response.data?.ensemble_label,
          ensemble_confidence: response.data?.ensemble_confidence,
        });
      }
    } catch (err) {
      console.error('Error sending data', err);
      setPredictionsAudio(null);
      setPredictionsVideo(null);
      showMessage('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * sendDataUrl: URL-based detection
   * If analyzeAudio==true => call /media (both audio & video analysis)
   * Otherwise => call /video (video only)
   */
  const sendDataUrl = async () => {
    if (!youtubeUrl.trim()) {
      showMessage('Please enter a valid YouTube URL.');
      return;
    }
    if (!language) {
      showMessage('Please select a model language.');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('youtube_url', youtubeUrl);
      formData.append('selected_language', language);

      let response;
      if (analyzeAudio) {
        // /media
        response = await axiosBackendApi.post('media', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const audioRes = response.data.audio_analysis;
        const videoRes = response.data.video_analysis;
        setPredictionsAudio({
          ensemble_label: audioRes?.ensemble_label,
          ensemble_confidence: audioRes?.Prob,
        });
        setPredictionsVideo({
          ensemble_label: videoRes?.ensemble_label,
          ensemble_confidence: videoRes?.ensemble_confidence,
        });
      } else {
        // /video
        response = await axiosBackendApi.post('video', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setPredictionsAudio(null);
        setPredictionsVideo({
          ensemble_label: response.data?.ensemble_label,
          ensemble_confidence: response.data?.ensemble_confidence,
        });
      }
    } catch (err) {
      console.error('Error sending data', err);
      setPredictionsAudio(null);
      setPredictionsVideo(null);
      showMessage('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // -------------------------------
  // Render
  // -------------------------------
  return (
    <Box mt={7}>
      <Card variant="outlined" sx={{ p: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }} className="upload-title">
            Detect Fake Video
          </Typography>
        </Box>

        {/* Tabs: Upload or YouTube */}
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="basic tabs"
          className="profile__tabs"
          sx={{
            backgroundColor: 'rgb(8, 89, 80,0.07) !important',
            width: { xs: '100%', sm: 'fit-content' },
            borderRadius: '10px',
            marginInline: { xs: 0, sm: '24px' },
            padding: '3px',
            border: '2px solid #EAECF0',
          }}
        >
          <Tab
            label="Upload Video"
            {...a11yProps(0)}
            sx={{
              paddingInline: { xs: 2, sm: 5 },
              textAlign: 'center',
              fontSize: { xs: '12px', sm: 'inherit' },
            }}
          />
          <Tab
            disabled
            label="YouTube URL"
            {...a11yProps(1)}
            sx={{
              color: '#000 !important',
              paddingInline: { xs: 2, sm: 5 },
              textAlign: 'center',
              fontSize: { xs: '12px', sm: 'inherit' },
              cursor: 'not-allowed !important',
            }}
          />
        </Tabs>

        {/* Panel 1: Upload Video */}
        <CustomTabPanel value={tabValue} index={0}>


          {/* Upload Form */}
          <UploadForm
            title=""
            selectedLanguage={language}
            handleLanguageChange={handleLanguageChange}
            keyFile={videoFile}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            acceptedFormats="video/*"
            maxFileSize="100MB"
            supportedFormats="MP4, WebM, OGG"
          />
          {/* checkbox */}
          <Box sx={{ mb: 2 }}>

            <FormControlLabel
              control={
                <Checkbox
                  checked={analyzeAudio}
                  onChange={(e) => setAnalyzeAudio(e.target.checked)}
                  className={'checkAudio'}
                />
              }
              label="Analyze Audio & Video (Multi Modal)"
            />
          </Box>
          {/* Uploaded file preview */}
          {videoFile && (
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f4f6f8',
                padding: '16px',
                borderRadius: '8px',
                border: '1px solid #d3daea',
              }}
            >
              {/* File Icon */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 50,
                  height: 50,
                  backgroundColor: '#e7ebf0',
                  borderRadius: '8px',
                  marginRight: 2,
                }}
              >
                <VideoFileOutlinedIcon sx={{ color: '#6c757d', fontSize: 32 }} />
              </Box>
              {/* File Details */}
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {videoFile.name}
                </Typography>
                <Typography variant="caption" sx={{ color: '#6c757d' }}>
                  Size: {(videoFile.size / (1024 * 1024)).toFixed(2)} MB &nbsp;|&nbsp;
                  Modified: {new Date(videoFile.lastModified).toLocaleString()}
                </Typography>
              </Box>

              {/* Remove File */}
              <IconButton
                onClick={handleRemove}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 50,
                  height: 50,
                  backgroundColor: '#e7ebf0',
                  borderRadius: '8px',
                  marginLeft: 2,
                }}
                disabled={loading || uploadLoading}
              >
                <DeleteIcon sx={{ color: '#ff6b6b', fontSize: 24 }} />
              </IconButton>
            </Box>
          )}
          <UploadSpinner uploadLoading={uploadLoading} />
          {/* Video Preview */}
          {videoFileSource && (
            <Box mt={3}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Preview:
              </Typography>
              <video
                src={videoFileSource}
                controls
                style={{
                  width: '70%',
                  marginLeft: '15%',
                  borderRadius: '20px',
                }}
              />
            </Box>
          )}

          {/* Detect Button */}
          <ButtonDetection
            onClick={sendData}
            disabled={!videoFile || uploadLoading}
            loading={loading}
            keyFile={predictionsVideo?.ensemble_label} // just a forced re-render
          />

          {/* Results if "media" was used => show Audio & Video, else => show Video only */}
          {/* If analyzeAudio is checked & we have valid results, show both */}
          {analyzeAudio && predictionsAudio?.ensemble_label && predictionsVideo?.ensemble_label && (
            <Box
              mt={4}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                border: '1px solid #eee',
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Audio Result */}
              <Box sx={{ textAlign: 'center', margin: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Audio Result
                </Typography>
                <ResultPercentage
                  key="audioResult"
                  label={predictionsAudio.ensemble_label}
                  probability={predictionsAudio.ensemble_confidence}
                  checkLabel="REAL"
                />
              </Box>

              {/* Video Result */}
              <Box sx={{ textAlign: 'center', margin: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Video Result
                </Typography>
                <ResultPercentage
                  key="videoResult"
                  label={predictionsVideo.ensemble_label}
                  probability={predictionsVideo.ensemble_confidence}
                  checkLabel="REAL"
                />
              </Box>
            </Box>
          )}

          {/* If not analyzing audio, or if only one part is valid => show video result only */}
          {!analyzeAudio && predictionsVideo?.ensemble_label && (
            <ResultPercentage
              key="videoResultOnly"
              label={predictionsVideo.ensemble_label}
              probability={predictionsVideo.ensemble_confidence}
              checkLabel="REAL"
            />
          )}
        </CustomTabPanel>

        {/* Panel 2: YouTube URL */}
        <CustomTabPanel value={tabValue} index={1}>
          <Box sx={{ mb: 2 }}>
            <label style={{ fontSize: '13px', color: '#000 !important' }}>
              Select a Model Language
            </label>
            <FormControl sx={{ width: '100%' }}>
              <Select
                id="language-select"
                value={language}
                onChange={handleLanguageChange}
                sx={{
                  marginBottom: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#d3daea',
                    borderRadius: '8px',
                  },
                  '& .MuiInputBase-input': {
                    color: '#909baf !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#29abe2',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#29abe2',
                  },
                }}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Arabic">Arabic</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={analyzeAudio}
                  onChange={(e) => setAnalyzeAudio(e.target.checked)}
                  className={'checkAudio'}
                />
              }
              label="Analyze Audio & Video (Multi Modal)"
            />
          </Box>

          {/* YouTube Input */}
          <FormControl sx={{ width: '100%' }}>
            <label style={{ fontSize: '13px', color: '#000 !important' }}>
              Enter YouTube URL
            </label>

            <TextField
              fullWidth
              placeholder="https://www.youtube.com/watch?v=..."
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#d3daea',
                    borderRadius: '8px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#29abe2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#29abe2',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#909baf !important',
                },
              }}
            />
          </FormControl>

          {/* Detect Button */}
          <ButtonDetection
            onClick={sendDataUrl}
            disabled={!youtubeUrl}
            loading={loading}
            keyFile={predictionsVideo?.ensemble_label}
          />

          {/* Results if "media" was used => show Audio & Video, else => show Video only */}
          {analyzeAudio && predictionsAudio?.ensemble_label && predictionsVideo?.ensemble_label && (
            <Box
              mt={4}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                border: '1px solid #eee',
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Audio Result */}
              <Box sx={{ textAlign: 'center', margin: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Audio Result
                </Typography>
                <ResultPercentage
                  key="audioResultUrl"
                  label={predictionsAudio.ensemble_label}
                  probability={predictionsAudio.ensemble_confidence}
                  checkLabel="REAL"
                />
              </Box>

              {/* Video Result */}
              <Box sx={{ textAlign: 'center', margin: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Video Result
                </Typography>
                <ResultPercentage
                  key="videoResultUrl"
                  label={predictionsVideo.ensemble_label}
                  probability={predictionsVideo.ensemble_confidence}
                  checkLabel="REAL"
                />
              </Box>
            </Box>
          )}

          {!analyzeAudio && predictionsVideo?.ensemble_label && (
            <ResultPercentage
              key="videoResultOnlyUrl"
              label={predictionsVideo.ensemble_label}
              probability={predictionsVideo.ensemble_confidence}
              checkLabel="REAL"
            />
          )}
        </CustomTabPanel>
      </Card>

      {/* Toast / Message component */}
      <Message message={message} open={open} onClose={handleClose} />
    </Box>
  );
};

export default Video;
